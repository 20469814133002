/* :root{
  --themeclr:#acf600
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

:root{
  --themeclr: #acf600;
  --textclr:#DEC508;
}

body {
  font-family: 'Chakra Petch', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
  background-color: #000 !important;
  /* background-image: url(./Assets/images/bg.png); */
  color:#fff;
  font-family: 'Chakra Petch', sans-serif !important;

}
@font-face {
  font-family: themefont;
  src: url(./Assets/Font/Gilroy-Black.ttf);
}
@font-face {
  font-family: thin;
  src: url(./Assets/Font/Gilroy-Thin.ttf);
}
@font-face {
  font-family: bold;
  src: url(./Assets/Font/Gilroy-Bold.ttf);
}
@font-face {
  font-family: extrabold;
  src: url(./Assets/Font/Gilroy-ExtraBold.ttf);
}
@font-face {
  font-family: medium;
  src: url(./Assets/Font/Gilroy-Medium.ttf);
}
@font-face {
  font-family: normal;
  src: url(./Assets/Font/Gilroy-Regular.ttf);
}
@font-face {
  font-family: light;
  src: url(./Assets/Font/Gilroy-Light.ttf);
}
table{
  color: #fff !important;
}
.list-group{
  background-color: #000;
}
.list-group a,.list-group-item{
  color: #fff !important;
  background-color: #000 !important;
}
.list-group-item.active{
  border-color: var(--themeclr) !important;
}
.list-group-item-action:hover, .list-group-item-action:focus,.list-group-item-action.active{
  background-color: var(--themeclr) !important;
}
.list-group-item-action:hover , .list-group-item-action:focus,.list-group-item-action.active {
  color: #000 !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

header, main, footer {
  padding-left: 300px;
}

@media only screen and (max-width : 992px) {
  header, main, footer {
    padding-left: 0;
  }
}
.jc-between{
  justify-content: space-between !important;
}

/* NEW CSS */
.logo{
  height: 45px;
}
.border-right,#sidebar-wrapper {
  border-right: 1px solid #2f2f2f !important;
}
.asrt-table-foot {
  color: #fff;
}
.navbar.bg-primary
{
  background: #000 !important;
  border-bottom: 1px solid #2f2f2f;
}
#page-content-wrapper .text-primary {
  color: var(--themeclr) !important;
}

.btn.btn-outline-primary,.btn-theme{
  /* background-image: linear-gradient(#7aff58, #a6f232, #e3fc0f) !important;
  color: black !important; */
  background: transparent !important;
  font-family: 'Chakra Petch', sans-serif !important;

    border-radius: 6px;
    position: relative;
    min-width: 120px;
    padding: 10px 20px;
    color: var(--themeclr);
    border-color: var(--themeclr);
    box-shadow:0 0 0 0rem rgba(0, 123, 255, 0.5) !important;

}
.form-control:focus
{
  box-shadow:0 0 0 0rem rgba(0, 123, 255, 0.5) !important;

}
.input-group.asrt-page-length span
{
background: #000000 !important;
}
.btn.btn-outline-primary:hover,.btn-theme:hover {
  background: var(--themeclr) !important;
  color: #000;
  border-color: var(--themeclr);

}
.table-bordered,.table thead th,.table-bordered td
{
  border-color:#525252 !important;
}
.page-link input
{
  background: #1a1a1a;
    display: inline-block;
    color: #fff;
    border:none !important;
    /* font-size: 12px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important; */
}
th.sortable
{
  background-image: url(./Assets/images/sort_icon.png) !important;

}
.btn_wallet_addr
{
  color:#fff !important;
}
.btn_wallet_addr:hover
{
  color:#000 !important;
}
/* .btn.btn-outline-primary:after,.btn-theme:after{
  content: "";
    width: 100%;
    height: 100%;
    border: 1px solid white;
    position: absolute;
    left: -5px;
    bottom: 4px;
    transition: 0.2s;
} */
.loginsec{
  background-color: #000 !important;
  border: 1px solid var(--themeclr) !important;
  border-radius: 14px !important;
}
.loginsec label {
  color: #fff;
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link,.navbar-dark .navbar-brand{
  color:#fff !important;
}
.btn-link
{
  color: var(--themeclr)   !important;
}
.input-group span {
  background: black !important;
  color: #fff;
  border-radius: 0;
}
.pagination li a {
  background: #000 !important;
}
th{
color: var(--themeclr) !important;
}


.card_dark_user.bg-secondary,.card.bg-primary{
   background-color: #0f0f0f !important;
   color: #fff !important;
   border: 1px solid var(--themeclr) !important;
   border-radius: 14px;

}
.btn-primary
{
/* background-color: #a1cb00   !important; */
background: var(--themeclr) !important;
border-color: var(--themeclr)   !important;
color:#000 !important;

    font-size: 16px;
    font-weight: 700 !important;
    line-height: 23px;
    letter-spacing: 0;
    text-align: center;
    border: 1px solid var(--themeclr);
    padding: 8px 10px;
    border-radius: 6px;
    box-shadow: 0px !important;
    box-shadow: 0 0 0 0rem rgba(38, 143, 255, 0.5) !important;

}
.btn-primary:hover
{
  background-color: transparent !important;
  color:var(--themeclr) !important;
}

.responsive-table .col-md-12{
    /* width: 100%; */
    overflow-x: auto;
    /* max-width: 100%; */
}
.responsive-table {
  margin-top: 33px;
  overflow: hidden;
}
.hut-cont label {
  color: #fff;
  margin-top: 10px;
}

.input-group span {
  background: #272727 !important;
  color: #fff;
  border-radius: 0 4px 4px 0;
  border: 0;
}


.ml-auto
{
  margin-left:auto !important;
}

.float-right {
  float: right !important;
}

@media only screen and (min-width:992px)
{
  .right_sec_align
  {
    display: flex;
    justify-content: flex-end;
  }

}
@media(max-width:991px){
  #sidebar-wrapper .list-group {
    width: 11rem !important;
}
}

.login_container_height
{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.form-control
{
  border-color:#4f5051 !important;
}
.btn-outline-secondary.btn-theme:hover
{
background-color: transparent !important;
color:#acf600 !important;
}
.btn-light
{
  background-color: #000 !important;
  border-color: #000 !important;
  color:#fff !important;
}
.modal-header,.modal-footer
{
  border-color:#434242 !important;
}
textarea.form-control
{
  height: 100px !important;
}
input[type="file"]
{
  background: transparent !important;
  border:none !important;
  padding-left: 0px !important;
}
.btn-secondary
{
  font-weight: 700 !important;
}
.btn-secondary:hover
{
  color:#6c757d !important;
  background-color: transparent !important;
}

@media only screen and (max-width:420px)
{
  .table_tools
  {
    margin-top: 20px;
  }
}

/* @media only screen and (min-width:768px) and (max-width:991px)
{
  .table_tools
  {
    margin-top: 20px;

  }
} */

@media only screen and (min-width:768px) and (max-width:991px)
{
  .toggled #page-content-wrapper
  {
    margin-left: 65px !important;
  }
}